// Solarized:
$base03:    #002b36;
$base02:    #073642;
$base01:    #586e75;
$base00:    #657b83;
$base0:     #839496;
$base1:     #93a1a1;
$base2:     #eee8d5;
$base3:     #fdf6e3;
$yellow:    #b58900;
$orange:    #cb4b16;
$red:       #dc322f;
$magenta:   #d33682;
$violet:    #6c71c4;
$blue:      #268bd2;
$cyan:      #2aa198;
$green:     #859900;

$brand-color: $blue; //lighten($blue, 10%);

// $text-color:       $base0;
// $brand-color:      $green;
// $pre-border-color: $base02;
//
// $grey-color:       $base01;
// $grey-color-light: lighten($grey-color, 40%);
// $grey-color-dark:  darken($grey-color, 25%);

@import "minima";

$bold: normal;

// $background-color: $base03;
$code-background:  $background-color;
$code-def-color:   $text-color;

// https://stackoverflow.com/questions/3249585
//*:not(a) {
//    -webkit-tap-highlight-color: rgba(0,0,0,0);
//}

/* Syntax highlighting */

.highlight {

  // background: $code-background;
  @extend %vertical-rhythm;

    // .highlighter-rouge & {
    //   background: $code-background;
    // }

  .c     { color: $base1; font-style: italic } // Comment
  .err   { color: $red; } // background-color: #e3d2d2 } // Error
  .k     { font-weight: $bold } // Keyword
  .o     { font-weight: $bold } // Operator
  .cm    { color: $base1; font-style: italic } // Comment.Multiline
  .cp    { color: #999; font-weight: $bold } // Comment.Preproc
  .c1    { color: $base1; font-style: italic } // Comment.Single
  .cs    { color: #999; font-weight: $bold; font-style: italic } // Comment.Special
  .gd    { color: #000; background-color: lighten($red, 40%) } // Generic.Deleted
  .gd .x { color: #000; background-color: lighten($red, 30%) } // Generic.Deleted.Specific
  .ge    { font-style: italic } // Generic.Emph
  .gr    { color: $red } // Generic.Error
  .gh    { color: #999 } // Generic.Heading
  .gi    { color: #000; background-color: lighten($cyan, 50%) } // Generic.Inserted
  .gi .x { color: #000; background-color: lighten($cyan, 40%) } // Generic.Inserted.Specific
  .go    { color: #888 } // Generic.Output
  .gp    { color: #555 } // Generic.Prompt
  .gs    { font-weight: $bold } // Generic.Strong
  .gu    { color: #aaa } // Generic.Subheading
  .gt    { color: $red } // Generic.Traceback
  .kc    { font-weight: $bold } // Keyword.Constant
  .kd    { font-weight: $bold } // Keyword.Declaration
  .kp    { font-weight: $bold } // Keyword.Pseudo
  .kr    { font-weight: $bold } // Keyword.Reserved
  .kt    { color: darken($violet, 10%); font-weight: $bold } // Keyword.Type
  .m     { color: $cyan } // Literal.Number
  .s     { color: $red } // Literal.String
  .na    { color: darken($cyan, 10%) } // Name.Attribute
  .nb    { color: $blue } // Name.Builtin
  .nc    { color: darken($violet, 10%); font-weight: $bold } // Name.Class
  .no    { color: darken($cyan, 10%) } // Name.Constant
  .ni    { color: darken($magenta, 10%) } // Name.Entity
  .ne    { color: darken($red, 20%); font-weight: $bold } // Name.Exception
  .nf    { color: darken($red, 20%); font-weight: $bold } // Name.Function
  .nn    { color: #555 } // Name.Namespace
  .nt    { color: darken($violet, 25%) } // Name.Tag
  .nv    { color: darken($cyan, 10%) } // Name.Variable
  .ow    { font-weight: $bold } // Operator.Word
  .w     { color: #bbb } // Text.Whitespace
  .mf    { color: $cyan } // Literal.Number.Float
  .mh    { color: $cyan } // Literal.Number.Hex
  .mi    { color: $cyan } // Literal.Number.Integer
  .mo    { color: $cyan } // Literal.Number.Oct
  .sb    { color: $red } // Literal.String.Backtick
  .sc    { color: $red } // Literal.String.Char
  .sd    { color: $red } // Literal.String.Doc
  .s2    { color: $red } // Literal.String.Double
  .se    { color: $red } // Literal.String.Escape
  .sh    { color: $red } // Literal.String.Heredoc
  .si    { color: $red } // Literal.String.Interpol
  .sx    { color: $red } // Literal.String.Other
  .sr    { color: $green } // Literal.String.Regex
  .s1    { color: $red } // Literal.String.Single
  .ss    { color: darken($magenta, 10%) } // Literal.String.Symbol
  .bp    { color: #999 } // Name.Builtin.Pseudo
  .vc    { color: darken($cyan, 10%) } // Name.Variable.Class
  .vg    { color: darken($cyan, 10%) } // Name.Variable.Global
  .vi    { color: darken($cyan, 10%) } // Name.Variable.Instance
  .il    { color: $cyan } // Literal.Number.Integer.Long

}

/* End syntax highlighting */

td pre, td code {
    border: none;
    margin: 0px;
    padding: 0px;
}

.highlight {
    background: none;
    .highlighter-rouge & {
        background: none;
    }
    * {
        background: none;
    }
}

table {
    margin-top: 25px;
    margin-bottom: 25px;
}

.theorem table {
    padding-top: 0px;
    margin-top: 10px;
    margin-bottom: 0px;
    border: none;
    th, td {
        background-color: lighten($brand-color, 45%);
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 2px;
        padding-bottom: 2px;
        margin: 0px;
        border: none;
    }
}

figure.diagram > * {
    display: block;
    margin: 0 auto;
}.inline-equation {
}

.equation {
    text-align: center;
    margin-bottom: 15px;
}$lightgrey: #dddddd;

.quick-links {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-evenly;
    justify-content: space-between;
    // justify-content: center;
    align-items: center;
    // margin-bottom: 15px;
    margin-top: 25px;
    // margin-bottom: 25px;
}

.button-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 25px;
    margin-bottom: 25px;
}

@media screen and (max-width: 480px) {
    // .quick-links {
    //     justify-content: space-evenly;
    // }
    .site-title {
        letter-spacing: -1.5px;
    }
}

@media screen and (max-width: 330px) {
    .quick-links {
        justify-content: space-evenly;
    }
}

// B5: 176 x 250 mm
.quick-link {
    width: 162px;
    height: 230px;
    border: 1px solid $lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flexible-quick-link {
    // width: 162px;
    height: 230px;
    border: 1px solid $lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
}

.supporting-quick-link {
    // width: 162px;
    height: 230px;
    border: 1px dashed $lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
}

.disabled-quick-link {
    width: 162px;
    height: 230px;
    border: 1px dashed $red;
    display: flex;
    justify-content: center;
    align-items: center;
}

.site-title:hover {
    text-decoration: none;
}

.button-cover {
    position: absolute;
    width: 162px;
    height: 230px;
}

#wave {
    // height: 140px;
    // text-align: center;
    // margin-bottom: 15px;
    // margin-top:30px;
}

#forside-pensumhefte, #forside-cormen, #forside-guide {
    // height: 300px;
    height: 230px;
}

#logo-inginious {
    width: 105px;
}

#logo-piazza {
    width: 120px;
}

figure a {
    outline: none;
}

// a:visited {
//     color: darken($brand-color, 10%);
// }

.slash {
    stroke-width: 8pt;
    stroke: $red;
    opacity: 0.7;
}

blockquote {
    @include relative-font-size(1);
    font-style: normal;
    letter-spacing: 0px;
}

.quote-source {
    text-align: right;
    padding-bottom: 30px;
    color: $grey-color;
    // @include relative-font-size(1.125);
}

.quote-source:before {
    content: "— ";
}

.algorithm {
    font-variant: small-caps;
}

em.alg {
    font-style: normal;
    font-variant: small-caps;
}

.footnotes {
    font-size: $small-font-size;
    font-style: normal;
    color: $grey-color;
}

// ----------------------------------------------------------------------------
// Pseudocode

// Header

p.alg {
    font-style: normal;
    font-variant: small-caps;
    margin-bottom: 0px;
}

// Lists of steps

ol.alg, ol.alg ol {
    list-style: none;
    margin-left: 0px;
    // Need to get non-extended bold and small caps before I can use this
    // outside the actual formulas.
    // font: normal 1.21rem KaTeX_Main, Times New Roman, serif;
    // .katex {
    //     font: normal 1.21rem KaTeX_Main, Times New Roman, serif;
    // }
}

ol.alg {
    counter-reset: algcounter;
}

ol.alg li:before {
    counter-increment: algcounter;
    content: counter(algcounter) ".";
    display: inline-block;
    width: 1.5em;
}

ol.alg ol li:before {
    width:3.6em;
}

ol.alg ol ol li:before {
    width: 5.7em;
}

ol.alg ol ol ol li:before {
    width: 7.8em;
}

ol.alg ol ol ol ol li:before {
    width: 9.9em;
}

ol.alg ol ol ol ol ol li:before {
    width: 12em;
}

td *.highlight {
    padding: 0px;
    margin: 0px;
}

td ol.alg {
    padding: 0px;
    margin: 0px;
}

td ol.alg li:before {
    content: "";
    width: 0em;
}

td ol.alg ol li:before {
    width:2.1em;
}

td ol.alg ol ol li:before {
    width: 4.2em;
}

td ol.alg ol ol ol li:before {
    width: 6.3em;
}

td ol.alg ol ol ol ol li:before {
    width: 8.4em;
}

td ol.alg ol ol ol ol ol li:before {
    width: 10.5em;
}

// ----------------------------------------------------------------------------

sup, sub {
  vertical-align: baseline;
  position: relative;
  top: -0.3em;
}
sub {
  top: 0.3em;
}

// ----------------------------------------------------------------------------

pre, code {
    background-color: $code-background;
}

/* .highlight { */
/*     background: $background-color; */
/*  */
/*     .highlighter-rouge & { */
/*         background: $background-color; */
/*     } */
/*  */
/*     .k, .o, .cp, .cs, .gs, .kc, .kd, .kp, .kr, .kt, .nc, .ne, .nf, .ow { */
/*         font-weight: normal; */
/*     } */
/*  */
/* } */

// ----------------------------------------------------------------------------

aside {
    margin-bottom: 15px;
    padding: 10px;
    padding-left: $spacing-unit / 2;
    background-color: lighten($brand-color, 45%);
    border-left: 4px solid $brand-color;
}

.note {
    margin-bottom: 15px;
    padding: 10px;
    padding-left: $spacing-unit / 2;
    background-color: lighten($brand-color, 45%);
    border-left: 4px solid  $brand-color;
}

.theorem {
    margin-bottom: 15px;
    padding: 10px;
    padding-left: $spacing-unit / 2;
    background-color: lighten($brand-color, 45%);
    border-left: 4px solid $brand-color;
}

.important {
    margin-bottom: 15px;
    padding: 10px;
    padding-left: $spacing-unit / 2;
    background-color: lighten($orange, 45%);
    border-left: 4px solid $orange;
}

.meta {
    border-left: 4px solid lighten($grey-color, 20%);
    background-color: lighten($grey-color, 45%);
    margin-bottom: 15px;
    padding: 10px;
    // padding-left: 13px;
    padding-left: $spacing-unit / 2;
    color: $grey-color;
}

.meta a {
    color: lighten(desaturate($brand-color, 40%), 10%);
}

.bottom-text-box {
    fill: lighten($brand-color, 45%);
}

.bottom-text {
    font-size: $small-font-size;
    fill: $brand-color;
}

.dimmed {
    color: $grey-color;
}

// ----------------------------------------------------------------------------

.button {
    background-color: $brand-color;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 6px;
    transition-duration: 0.2s;
    cursor: pointer;
}
.button:hover {
    background-color: darken($brand-color, 10%);
}

.inactive-button {
    background-color: $grey-color;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    border-radius: 6px;
    transition-duration: 0.2s;
}

// ----------------------------------------------------------------------------

// https://codepen.io/ArielBeninca/pen/KdVJGO

.container {
    padding-top: 15px;
    padding-bottom: 30px;
}
.toggle {
    &:first-child {
        border-top: 1px solid $lightgrey;
    }
    &:last-child {
        border-bottom: 1px solid $lightgrey;
    }
    .toggle-title {
        position: relative;
        display: block;
        // border-top: 1px solid $lightgrey;
        margin-bottom: 0px;
        // line-height: 1.2;
        line-height: 1;
        margin-top: 2px;

        h3 {
            @include relative-font-size(1);
            // font-size: 20px;
            cursor: pointer;
            font-weight: 600;
            // font-weight: 200;
            margin-right: 20px;
            // color: $lightgrey;
            // color: $base02;
        }
        &.active h3 {}
    }
    .toggle-inner {
        // padding: 7px 25px 10px 25px;
        // padding: 7px 25px 10px 0px;
        // padding: 7px 25px 0px 0px;
        padding: 0px 0px 0px 0px;
        display: none;
        // margin: -7px 0 6px;
        // background-color: $base3;
        div {
            max-width: 100%;
        }
    }
    .toggle-title {
        .title-name {
            display: block;
            // padding: 25px 25px 14px;
            // padding: 25px 0px 10px;
            padding: 15px 0px 2px;
        }
        a i {
            font-size: 22px;
            margin-right: 0px;
        }
        i {
            position: absolute;
            background: url('/assets/plus_minus.png') 0px -24px no-repeat;
            width: 24px;
            height: 24px;
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            transition: all 0.3s ease;
            // margin: 20px;
            // right: 0;
            right: -8px;
            top: 11px;
        }
        &.active i {
            background: url('/assets/plus_minus.png') 0px 0px no-repeat;
        }
    }
}
